import React, { memo, useState } from 'react';
import { base_url, Form, Row, Col } from "../../../base";

const MySingleImage = ({ name, label, accept, onChange, formik, fieldName }) => {
    const [fileName, setFileName] = useState("Select Profile");
    const [showImg, setShowImg] = useState("")
    const [fileInputKey, setFileInputKey] = useState(Date.now()); // To reset the input field
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
            if (file.size <= maxFileSize) {
                let img = URL.createObjectURL(file);
                setShowImg(img);
                setFileName(file.name);
            } else {
                // Handle the case when the file is too large
                alert("File size exceeds 2MB. Please upload a smaller file.");
                event.target.value = '';
                return;
            }
        }
        if (onChange) {
            onChange(event);
        }
    };
    const handleRemoveClick = () => {
        setShowImg("");
        setFileName("Select Profile");
        setFileInputKey(Date.now()); // Reset the input field
        if (formik) {
            formik.setFieldValue('base64File', "");
            formik.setFieldValue('fileName', "");
            formik.setFieldValue(fieldName, "");
        }
    }
    return (
        <Row className="d-flex justify-content-end">
            <Col sm="2">
                <Form.Label>{label}</Form.Label>
            </Col>
            <Col sm="10" className='mb-2'>
                <div className="input-group">
                    <div className="custom-file">
                        <input
                            key={fileInputKey} // Reset the input field by changing the key
                            type='file'
                            name={name}
                            accept={accept}
                            onChange={handleFileChange}
                            className="custom-file-input"
                            id="customFile"
                        />
                        <label className="custom-file-label" htmlFor="customFile">{fileName}</label>
                    </div>
                    <div className="input-group-append">
                        {(showImg || formik.values[fieldName]) ? <div>
                            <img src={showImg ? showImg : formik.values[fieldName] ? `${base_url()}/${fieldName == "campaign_file" ? "campaign" : "contacts"}/${formik.values[fieldName]}` : ""} className="ml-2 rounded" alt="Selected" width={40} height={34} />
                            <i className='i-Close text-danger font-weight-bold cursor-pointer mx-2' style={{ fontSize: 16 }} onClick={() => handleRemoveClick()} />
                            <i className='i-Eye font-weight-bold cursor-pointer' style={{ fontSize: 16, color: "#24aaef" }} onClick={() => { window.open(showImg, "_blank"); }} />
                        </div> : null}
                    </div>
                </div>
            </Col>
        </Row>
    );
}
export default memo(MySingleImage);